<template>
  <div>
    <div id="container" style="width:100%; height:100%" class="container">
      

    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
import maps from "qqmap"
export default {
  created() {    
    this.init();
  },
  data() {
    return {
      map: null,
      getAddress: null,
      getAddCode: null,
      shopInfo:{
        lng:'',
        lat:''
      },
      addressKeyword: '' 
    };
  },
  watch:{
    showModal: function(newValue) {
      if (newValue) {
        this.init();
      }
    }
  },

  methods: {
    //初始化地图
   init() {
     var that = this;
     maps.init("W3YBZ-NW734-APUUL-XQTC4-76L2T-NCF70", () => {
       var myLatlng = new maps.LatLng(43.86423, 125.2912);
       var myOptions = {
         zoom: 16,
         center: myLatlng,
         mapTypeId: maps.MapTypeId.ROADMAP
       };
       that.map = new maps.Map(
         document.getElementById("container"),
         myOptions
       );
       //获取点击后的地址
       maps.event.addListener(that.map, "click", function(event) {
         // 获取点击后的地图坐标
         //alert('addListener:'+event.latLng.getLng()+'-'+event.latLng.getLat());
         that.shopInfo.lng = event.latLng.getLng();
         that.shopInfo.lat = event.latLng.getLat();
         that.getAddressCode();
       });
 
       //调用地址显示地图位置并设置地址
       that.getAddress = new maps.Geocoder({
         complete: function(result) {
           that.map.setCenter(result.detail.location);
           console.log(result.detail.location)
           that.shopInfo.lng = result.detail.location.lng;
           that.shopInfo.lat = result.detail.location.lat;
           var marker = new maps.Marker({
             map: that.map,
             position: result.detail.location
           });
         }
       });
       //通过坐标来显示地图地址
       that.getAddCode = new maps.Geocoder({
         complete: function(result) {
           that.addressKeyword = result.detail.address;
         }
       });
     });
   },
   //通过地址获得位置
  getAddressKeyword() {
    //通过getLocation();方法获取位置信息值
    this.getAddress.getLocation(this.addressKeyword);
  },
  // 通过坐标获得地址
  getAddressCode() {
    var lat = parseFloat(this.shopInfo.lat);//纬度
    var lng = parseFloat(this.shopInfo.lng);//经度
    alert(lat+'-'+lng);   
    var latLng = new maps.LatLng(lat, lng);
    //调用获取位置方法
    this.getAddCode.getAddress(latLng);
    alert(this.addressKeyword); 
  }
    
  }
  
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .ll {
    width: 60%;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }   
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
  .listitem1 {
    overflow: hidden;
    height: 270px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .iconkg {
        margin-top: 34px;
        width: 320px;  
        height: 203px;   
        float: right;
        margin-right: 34px;
      }

      
    }
    
  }
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
.button1 {
  width: 100%;
  height: 88px;
  text-align: center;
  overflow: hidden;
  font-size: 32px;
  line-height: 88px;
  color: #000000;
  background: rgba(238, 238, 238, 1);
  border-radius: 8px;
  cursor: pointer;
  &.primary {
    background: linear-gradient(90deg, rgba(31, 214, 199, 1) 0%, rgba(16, 189, 241, 1) 100%);
    color: #fff;
  }
  &:active {
    opacity: 0.8;
  }
  &.mb {
    margin-top: 54px;
  }
  &.mt {
    margin-top: 20px;
  }
}
</style>